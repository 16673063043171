<template>
  <div>
    <Header />
 <br /><br /><br /><br /><br /><br /><br />
    <div class="container fluid">
      <div class="row" style="margin-top: 50px">
        <div class="col-md-6 col-xl-3">
          <!-- Simple card -->
          <a @click="dx250()">
            <div class="card" style="width: 260px; height: 180px">
              <img
                style="max-height: 80%; max-width: 60%; margin: auto"
                class="card-img-top img-fluid"
                src="../../../assets/images/subcategorie/auxiliary_equipement/adhesif_and_liquid_dispenser/DX-250.jpg"
                alt="Card image cap"
              />

              <div class="card-body">
                <h4 style="text-align: center" class="card-title">DX-250 </h4>
              </div>
            </div>
          </a>
        </div>
        <!-- end col -->
        <div class="col-md-6 col-xl-3">
          <!-- Simple card -->
          <a @click="dx255()">
            <div class="card" style="width: 260px; height: 180px">
              <img
                style="max-height: 80%; max-width: 60%; margin: auto"
                class="card-img-top img-fluid"
                src="../../../assets/images/subcategorie/auxiliary_equipement/adhesif_and_liquid_dispenser/DX-255.jpg"
                alt="Card image cap"
              />
              <div class="card-body">
                <h4 style="text-align: center" class="card-title">DX-255 </h4>
              </div>
            </div>
          </a>
        </div>
        <!-- end col -->
        <div class="col-md-6 col-xl-3">
          <!-- Simple card -->
          <a @click="dx350()">
            <div class="card" style="width: 260px; height: 180px">
              <img
                style="max-height: 80%; max-width: 60%; margin: auto"
                class="card-img-top img-fluid"
                src="../../../assets/images/subcategorie/auxiliary_equipement/adhesif_and_liquid_dispenser/DX-350.jpg"
                alt="Card image cap"
              />
              <div class="card-body">
                <h4 style="text-align: center" class="card-title">DX-350</h4>
              </div>
            </div>
          </a>
        </div>
        <!-- end col -->
        
      </div>
    </div>
    <Footer />
  </div>
</template>
<script>
import Header from "../../../components/Header.vue";

import Footer from "../../../components/Footer.vue";

/**
 * Dashboard component
 */
export default {
  components: {
    Header,

    Footer,
  },
  methods: {
    dx250(){
       window.location.href = "https://store.metcal.com/en-us/shop/fluid-dispensing/dispensers/DX-250";

    },

dx255(){
   window.location.href = "https://store.metcal.com/en-us/shop/fluid-dispensing/dispensers/DX-255";

    },

dx350(){
   window.location.href = "https://store.metcal.com/en-us/shop/fluid-dispensing/dispensers/DX-350";
    
    },
  },
};
</script>